import api from "../service";

const getAuthenticate = async ({ commit }, object) => {
  const response = await api.getAuthenticate(object);
  commit("SET_USER_AUTHENTICATED", response.data.data);
  return response;
};

const getUserInformationWithToken = async ({ commit }) => {
  const response = await api.getUserInformationWithToken();
  commit("SET_USER_AUTHENTICATED", response.data);
  return response;
};

export default {
  getAuthenticate,
  getUserInformationWithToken,
};

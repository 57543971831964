import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { VueMaskDirective } from "v-mask";
import Services from "./services";
import vuescroll from "vuescroll";

import * as VueGoogleMaps from "vue2-google-maps"; // Import package

import "./assets/stylus/main.styl";
import "vue-multiselect-listbox/dist/vue-multi-select-listbox.css";
Vue.directive("mask", VueMaskDirective);

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyAFDHB8_l3STEEdgo3f2O2Gc-77aGtUBa8",
    libraries: "places",
  },
});
Vue.use(vuescroll);

Services.init();

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

<template>
  <v-snackbar top v-model="show" :timeout="5000" color="warning">
    {{ dicionary["pt-BR"].general.errors.code[errors.code] }}

    <template v-slot:action="{ attrs }">
      <v-btn icon color="white" text v-bind="attrs" @click="show = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapGetters } from "vuex";
import dicionary from "./dicionary/index.json";

export default {
  data() {
    return {
      dicionary: dicionary,
      show: false,
    };
  },

  watch: {
    errors: {
      handler: function (newValue) {
        this.show = true;
        console.log("Person with ID:" + newValue.id + " modified");
        console.log("New age: " + newValue.age);
      },
      deep: true,
    },
  },

  computed: {
    ...mapGetters({ errors: "$_errors/errors" }),
  },
};
</script>

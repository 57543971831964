import axios from "axios";
import Router from "../router";
import JWT from "./jwt";
import store from "../store";

// const config = {
//   baseURL: process.env.VUE_APP_ROOT_API,
// headers: {
//   "Content-Type": "application/json",
//   Accept: "application/json",
//   Authorization: `Bearer ${JWT.getToken()}`
// }
// };

const Api = {
  async init() {
    axios.defaults.baseURL = process.env.VUE_APP_ROOT_API;
    axios.defaults.headers.common["Accept"] = "application/json";
    const token = JWT.getToken();
    if (token) {
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    } else {
        console.log("Token is null");
    }
    ["get", "post", "put", "delete"].forEach((method) => {
      axios.defaults.headers[method]["Content-Type"] = "application/json";
    });
    axios.interceptors.response.use(function (response) {
      return response;
    }, this.handlerError);
  },
  async handlerError(error) {
    switch (error.response?.status) {
      case 401:
        JWT.destroyToken();
        Router.push({ name: "login" });
        return "Não Autenticado";
      case 403:
        JWT.destroyToken();
        throw new Error(error);
      case 404:
        store._modules.root._children.$_errors.state.errors =
          error.response.data;
        throw new Error(error);
      default:
        store._modules.root._children.$_errors.state.errors =
          error.response.data;
        throw new Error(error);
    }
  },
  async query(resource, data) {
    try {
      const result = await axios.get(resource, { params: data });
      return result;
    } catch (error) {
      throw new Error(error);
    }
  },

  get(resource, slug = "", params) {
    console.log(params);
    return new Promise((resolve) => {
      const result = axios.get(`${resource}/${slug}`, { params });
      resolve(result);
    });
  },

  async post(resource, params) {
    return new Promise((resolve) => {
      const result = axios.post(`${resource}`, params);
      resolve(result);
    });
  },

  async put(resource, id, params) {
    return new Promise((resolve) => {
      const result = axios.put(`${resource}/${id}`, JSON.stringify(params));
      resolve(result);
    });
  },

  patch(resource, id, params) {
    return axios.patch(`${resource}/${id}`, JSON.stringify(params));
  },

  delete(resource, id) {
    return new Promise((resolve) => {
      const result = axios.delete(`${resource}/${id}`);
      resolve(result);
    });
  },
};

export default Api;

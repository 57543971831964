import JWT from "../../../services/jwt";
import Api from "@/services";

const getAuthenticate = async (object) => {
  try {
    const result = await Api.post("auth", object);
    JWT.saveToken(result.data?.data?.auth?.token);
    await Api.init();
    return result;
  } catch (error) {
    console.log(error);
    throw new Error(error);
  }
};

const getUserInformationWithToken = async () => {
  try {
    const result = await Api.query("v1/users/check");
    return result;
  } catch (error) {
    console.log(error);
    throw new Error(error);
  }
};

export default { getAuthenticate, getUserInformationWithToken };

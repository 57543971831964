<template>
  <v-app>
    <sidebar v-if="$route.name != 'Login'"></sidebar>
    <v-main>
      <v-container>
        <PageTitle v-if="$route.name != 'Login'"></PageTitle>
        <router-view />
        <Errors />
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import Sidebar from "./components/Sidebar";
import PageTitle from "./components/UI/PageTitle";
import { mapGetters } from "vuex";
import storeAuth from "./modules/auth/store";
import storeErrors from "./modules/errors/store";
import Errors from "./modules/errors";

const STORE_KEY = "$_auth";
const STORE_KEY_ERRORS = "$_errors";

export default {
  name: "App",
  components: {
    Sidebar,
    PageTitle,
    Errors,
  },

  computed: {
    ...mapGetters({ authenticated: "$_auth/authenticated" }),
    ...mapGetters({ errors: "$_errors/errors" }),
  },

  created() {
    if (!(STORE_KEY in this.$store._modules.root._children)) {
      this.$store.registerModule(STORE_KEY, storeAuth);
    }
    if (!(STORE_KEY_ERRORS in this.$store._modules.root._children)) {
      this.$store.registerModule(STORE_KEY_ERRORS, storeErrors);
    }
  },
};
</script>

<template>
  <v-navigation-drawer
    class="elevation-3"
    mini-variant
    app
    v-model="drowner"
    permanent
    mini-variant-width="100px"
  >
    <hr class="mx-3 my-5" style="opacity: 0.1" />

    <v-list>
      <v-tooltip right v-for="item in items" :key="item.icon">
        <template v-slot:activator="{ on, attrs }">
          <v-list-item link :to="item.to" v-bind="attrs" v-on="on">
            <v-list-item-icon>
              <v-icon v-bind="attrs" v-on="on">{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <span>{{ item.title }}</span>
      </v-tooltip>
    </v-list>
    <template v-slot:append>
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-list-item link v-bind="attrs" @click="logout" v-on="on">
            <v-list-item-icon>
              <v-icon v-bind="attrs" v-on="on">mdi-exit-to-app</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Desconectar</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <span>Desconectar</span>
      </v-tooltip>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";
import jwt from "../services/jwt";
export default {
  computed: {
    ...mapGetters({ authenticated: "$_auth/authenticated" }),
  },
  data() {
    return {
      drowner: true,
      items: [
        {
          title: "Dashboard",
          icon: "mdi-chart-arc",
          to: "/dashboard",
        },
        {
          title: "Clientes",
          icon: "mdi-account-box-multiple",
          to: "/customers",
        },
        { title: "Terminal", icon: "mdi-television", to: "/terminal" },
        { title: "Conteúdos", icon: "mdi-play-box-outline", to: "/contents" },
        { title: "Usuários", icon: "mdi-account-outline", to: "/users" },
        { title: "Playlist", icon: "mdi-playlist-play", to: "/playlists" },
        { title: "RSS", icon: "mdi-rss", to: "/rss" },
        {
          title: "Anunciar",
          icon: "mdi-briefcase-account-outline",
          to: "/advertise",
        },
        { title: "Instagram", icon: "mdi-instagram", to: "/instagram" },
        // { title: "", icon: "mdi-cog-outline" },
      ],
    };
  },
  methods: {
    logout() {
      jwt.destroyToken();
      this.$router.push({ name: "Login" });
    },
  },
};
</script>

<style lang="stylus">
.v-application {
  // background-color: #F3F6F9 !important;
}
</style>

import Vue from "vue";
import VueRouter from "vue-router";

import Authentication from "./middlewares/auth";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    meta: {
      title: "Dashboard",
      subtitle: "Dados tratados",
      requiresAuth: true,
    },
    component: () => import("../modules/dashboard"),
  },
  {
    path: "/contents",
    name: "Content",
    meta: {
      title: "Conteúdos",
      subtitle: "Visualização dos conteúdos",
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../modules/contents"),
    children: [
      {
        path: "create",
        name: "CreateContent",
        meta: {
          title: "Conteúdos",
          subtitle: "Criação de conteúdo",
        },
        component: () => import("../modules/contents/create"),
      },
      {
        path: ":id/delete",
        name: "DeleteContent",
        meta: {
          title: "Conteúdos",
          subtitle: "Remover conteúdo",
        },
        component: () => import("../modules/contents/delete"),
      },
      {
        path: ":id/duplicate",
        name: "DuplicateContent",
        meta: {
          title: "Conteúdos",
          subtitle: "Duplicar conteúdo",
        },
        component: () => import("../modules/contents/duplicate"),
      },
      {
        path: ":id/edit",
        name: "EditContent",
        meta: {
          title: "Conteúdos",
          subtitle: "Editar conteúdo",
        },
        component: () => import("../modules/contents/create"),
      },
    ],
  },
  {
    path: "/rss",
    name: "Rss",
    meta: {
      title: "Fontes RSS",
    },
    component: () => import(/* webpackChunkName: "about" */ "../modules/rss"),
    children: [
      {
        path: "create",
        name: "CreateRss",
        meta: {
          title: "Fontes RSS",
          subtitle: "Adicionar Fontes RSS",
        },
        component: () => import("../modules/rss/create"),
      },
      {
        path: "players",
        name: "PlayersRss",
        meta: {
          title: "Fontes RSS",
        },
        component: () => import("../modules/rss/players"),
      },
      {
        path: ":id/edit",
        name: "EditRss",
        meta: {
          title: "Fontes RSS",
          subtitle: "Editar Fontes RSS",
        },
        component: () => import("../modules/rss/create"),
      },
      {
        path: ":id/delete",
        name: "DeleteRss",
        meta: {
          title: "Fontes RSS",
          subtitle: "Excluir Fontes RSS",
        },
        component: () => import("../modules/rss/delete"),
      },
    ],
  },
  {
    path: "/customers",
    name: "Customers",
    meta: {
      title: "Clientes e Agências",
      subtitle: "Visualizar Clientes e Agências",
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../modules/customers"),
    children: [
      {
        path: "createcustomer",
        name: "CreateCustomer",
        meta: {
          title: "Clientes e Agências",
          subtitle: "Criar Clientes e Agências",
        },
        component: () => import("../modules/customers/create"),
      },
      {
        path: ":id/deletecustomer",
        name: "DeleteCustomer",
        meta: {
          title: "Clientes e Agências",
          subtitle: "Deletar Clientes e Agências",
        },
        component: () => import("../modules/customers/delete"),
      },
      {
        path: ":id/editcustomer",
        name: "EditCustomer",
        meta: {
          title: "Clientes e Agências",
          subtitle: "Editar Clientes e Agências",
        },
        component: () => import("../modules/customers/create"),
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: () => import(/* webpackChunkName: "about" */ "../modules/auth"),
  },
  {
    path: "/playlists",
    name: "Playlists",
    meta: {
      title: "Playlists",
      subtitle: "Visualização das Playlists",
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../modules/playlists"),
    children: [
      {
        path: ":id/delete",
        name: "DeletePlaylists",
        meta: {
          title: "Playlists",
          subtitle: "Deletar playlists",
        },
        component: () => import("../modules/playlists/delete"),
      },
      {
        path: ":id/edit",
        name: "EditPlaylists",
        meta: {
          title: "Playlists",
          subtitle: "Editar Playlists",
        },
        component: () => import("../modules/playlists/create"),
      },
      {
        path: "create",
        name: "CreatePlaylists",
        meta: {
          title: "Playlists",
          subtitle: "Criar playlist",
        },
        component: () => import("../modules/playlists/create"),
      },
    ],
  },
  {
    path: "/users",
    name: "User",
    meta: {
      title: "Usuários",
      subtitle: "Visualização dos usuários",
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "about" */ "../modules/users"),
    children: [
      {
        path: ":id/edit",
        name: "EditUser",
        meta: {
          title: "Conteúdos",
          subtitle: "Editar usuário",
        },
        component: () => import("../modules/users/create"),
      },
      {
        path: ":id/delete",
        name: "DeleteUser",
        meta: {
          title: "Conteúdos",
          subtitle: "Deletar usuário",
        },
        component: () => import("../modules/users/delete"),
      },
      {
        path: "create",
        name: "CreateUser",
        meta: {
          title: "Usuários",
          subtitle: "Criar usuário",
        },
        component: () => import("../modules/users/create"),
      },
    ],
  },
  {
    path: "/terminal",
    name: "Terminal",
    meta: {
      title: "Terminal",
      subtitle: "Visualização do terminal",
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../modules/terminal"),
    children: [
      {
        path: "create",
        name: "CreateTerminal",
        meta: {
          title: "Terminal",
          subtitle: "Criar terminal",
        },
        component: () => import("../modules/terminal/create"),
      },

      {
        path: ":id/playlist",
        name: "Schedule",
        meta: {
          title: "Terminal",
          subtitle: "Criar Schedule",
        },
        component: () => import("../modules/playlists/schedule"),
      },
      {
        path: ":id/edit",
        name: "EditTerminal",
        meta: {
          title: "Terminal",
          subtitle: "Editar terminal",
        },
        component: () => import("../modules/terminal/create"),
      },
      {
        path: ":id/delete",
        name: "DeleteTerminal",
        meta: {
          title: "Terminal",
          subtitle: "Deletar terminal",
        },
        component: () => import("../modules/terminal/delete"),
      },
      {
        path: ":id/copy",
        name: "CopyTerminal",
        meta: {
          title: "Terminal",
          subtitle: "Duplicar conteúdo",
        },
        component: () => import("../modules/terminal/copy"),
      },
    ],
  },
  {
    path: "/advertise",
    name: "Advertise",
    meta: {
      title: "Anunciar",
      subtitle: "Selecione a localidade",
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../modules/advertise"),
  },
  {
    path: "/instagram",
    name: "Instagram",
    meta: {
      title: "Instagram",
      subtitle: "Visualização dos instagram",
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../modules/instagram"),
    children: [
      {
        path: ":id/edit",
        name: "EditInstagram",
        meta: {
          title: "Instagram",
          subtitle: "Editar usuário",
        },
        component: () => import("../modules/instagram/create"),
      },
      {
        path: ":id/delete",
        name: "DeleteInstagram",
        meta: {
          title: "Instagram",
          subtitle: "Deletar Instagram",
        },
        component: () => import("../modules/instagram/delete"),
      },
      {
        path: "create",
        name: "CreateInstagram",
        meta: {
          title: "Instagram",
          subtitle: "Criar usuário",
        },
        component: () => import("../modules/instagram/create"),
      },
    ],
  },
  { path: "*", redirect: "login" },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.name === from.name) {
    return next();
  }
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const authPermission = await Authentication.verifyAuth();
    console.log(authPermission);
    if (!authPermission) {
      next({ name: "Login" });
    }
    next();
  } else {
    next();
  }
});

export default router;
